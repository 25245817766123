<template>
	<div class="app-container">
		<!-- filter -->
		<div class="filter-container">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">关键字:</label>
				<el-input v-model="searchKey" style="width: 200px;" placeholder="角色名称"></el-input>
			</div>
			<!-- button -->
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="handleFilter">查询</el-button>
				<button-permissions :datas="'addRoles'">
					<el-button type="primary" style="margin-left:30px;width:100px;" @click="handleAddCoupon">添加</el-button>
				</button-permissions>
			</div>
		</div>
		<!-- table -->
		<div class="table-container">
			<el-table :data="tableData" v-loading="loading" style="width: 100%">
				<el-table-column prop="roleName" label="角色名称"></el-table-column>
				<el-table-column prop="userCount" label="员工数量"></el-table-column>
				<el-table-column prop="createDate" label="创建时间"></el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scop">
						<button-permissions :datas="'editRoles'">
							<div class="table-button" @click="handleTableEdit(scop.row)">编辑</div>
						</button-permissions>

						<button-permissions :datas="'deleteRoles'">
							<div
								class="table-button"
								style="color:#F56C6C"
								@click="handleTableDelete(scop.row.id)"
								v-if="scop.row.id != 1"
							>删除</div>
						</button-permissions>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				v-if="total"
				style="margin-top:20px;float:right;"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="pageNo"
				:page-sizes="[10, 20, 30, 40, 50]"
				:page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="total"
			></el-pagination>
		</div>
	</div>
</template>

<script>

import { employeerolelist, employeeroledelete } from '@/api/api.js';

import buttonPermissions from '@/components/buttonPermissions';

export default {
	components: {
		buttonPermissions
	},
	data () {
		return {
			searchKey: '',
			tableData: [],
			pageNo: 1,
			pageSize: 20,
			total: 0,
			loading: false
		}
	},
	created () {
		this.getList();
	},
	methods: {
		// 获取列表数据
		async getList () {
			this.loading = true;
			try {
				let data = {
					keyWords: this.searchKey, // 搜索关键字
					pageNo: this.pageNo,
					pageSize: this.pageSize
				}
				let result = await employeerolelist(data);

				this.total = result.data.total;
				this.tableData = result.data.records;

			} catch (error) {
				console.log(error);
			} finally {
				this.loading = false;
			}
		},
		handleFilter () {
			this.pageNo = 1;
			this.getList()
		},
		handleAddCoupon () {
			this.$router.push({
				path: '/setting/roleManage/editRoleManage'
			})
		},
		handleTableEdit (row) {
			this.$router.push({
				path: '/setting/roleManage/editRoleManage',
				query: {
					id: row.id,
					name: row.roleName,
					remarks: row.remarks
				}
			})
		},
		// table删除
		handleTableDelete (id) {

			this.$confirm('是否确认删除这个角色？删除后不可恢复', '提示', {
				confirmButtonText: '确认删除',
				cancelButtonText: '关闭',
				type: 'warning'
			}).then(() => {
				this.sureDelete(id);
			}).catch(() => {
			})
		},
		async sureDelete (id) {
			const res = await employeeroledelete({
				id: id
			})

			if (res.success) {
				this.$message({
					type: 'success',
					message: '删除成功'
				})
				this.getList()
			} else {
				this.$message({
					showClose: true,
					type: 'error',
					message: res.alertMsg
				})
			}
		},
		// 切换显示条数
		handleSizeChange (val) {
			this.pageSize = val;
			this.getList();
		},
		// 翻页
		handleCurrentChange (val) {
			this.pageNo = val;
			this.getList();
		}
	}
}
</script>

<style lang="less" scoped>
.app-container {
	background: #fff;
}
.table-button {
	color: #409eff;
	display: inline-block;
	margin-right: 20px;
	cursor: pointer;
}

.dialog {
	width: 100%;

	/deep/.el-dialog__header {
		border-bottom: 1px solid #ddd;
	}

	.content {
		padding: 0 30px;
		display: flex;
		flex-direction: row;

		.right {
			margin-left: 20px;
		}

		.text {
			margin-left: 20px;
			font-size: 14px;
			color: #999;
			line-height: 40px;
		}
	}

	.button {
		margin: 0 auto;
		width: 200px;
	}
}

.data-dialog {
	.value {
		line-height: 50px;
		font-size: 24px;
		color: #000;
	}

	.item {
		color: #666;
		margin-top: 10px;
	}
}
</style>
